import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '2, 103, 199',
		'primary-dark': '0, 81, 159',
		'accent': '255, 226, 0',
		'accent-plus': '0, 0, 0',
	},
});
